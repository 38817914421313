function sbgc(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

function sbUrlParam(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

!sbgc('sburl') && (window.document.cookie = "sburl="+ window.location.href +"; path=/;  max-age=3888000; domain=.simplebackups.com; secure; samesite=lax")
!sbgc('sbrefurl') && !!window.document.referrer && (window.document.cookie = "sbrefurl="+ window.document.referrer +"; path=/;  max-age=3888000; domain=.simplebackups.com; secure; samesite=lax")

!!sbUrlParam('sb_source') && (window.document.cookie = "sb_source="+ sbUrlParam('sb_source') +"; path=/;  max-age=3888000; domain=.simplebackups.com; secure; samesite=lax")
!!sbUrlParam('sb_term') && (window.document.cookie = "sb_term="+ sbUrlParam('sb_term') +"; path=/;  max-age=3888000; domain=.simplebackups.com; secure; samesite=lax")
!!sbUrlParam('sb_plan') && (window.document.cookie = "sb_plan="+ sbUrlParam('sb_plan') +"; path=/;  max-age=3888000; domain=.simplebackups.com; secure; samesite=lax")
